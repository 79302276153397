import { LitElement, html } from 'lit-element';
import expression from 'logical-expression-parser';
import { connect } from '../core/connect';
import { withProduct } from '../core/resolveProperties';
import * as descriptors from '../core/descriptors';

export const removeWhitespace = str => str.replace(/(\r\n|\n|\r|\s)+/gm, '');

export class When extends withProduct(LitElement) {
  static get properties() {
    return {
      ...super.properties,
      state: { type: Object, attribute: false },
      test: { type: String }
    };
  }

  render() {
    const result =
      this.test &&
      expression.parse(removeWhitespace(this.test), key => descriptors[key] && descriptors[key](this.state, this));

    if (result) {
      return html`
        <slot></slot>
      `;
    }
    return html``;
  }

  shouldUpdate(changedProperties) {
    return (
      changedProperties.size &&
      ((this.product && this.product.id in this.state.autoshipEligible && this.product.id in this.state.inStock) ||
        !this.product.id)
    );
  }
}

export const mapStateToProps = state => ({
  state
});

export const ConnectedWhen = connect(mapStateToProps)(When);

export default ConnectedWhen;
