import { html, css } from 'lit-element';
import {
  makeOptedinSelector,
  makeProductDefaultFrequencySelector,
  makeProductFrequencySelector,
  configSelector,
  templatesSelector
} from '../core/selectors';
import { connect } from '../core/connect';
import { subscribed } from '../core/props';
import { withProduct } from '../core/resolveProperties';
import { TemplateElement } from '../core/base';

export class OptinStatus extends withProduct(TemplateElement) {
  static get properties() {
    return {
      subscribed,
      frequencyMatch: { type: Boolean, reflect: true, attribute: 'frequency-match' },
      productDefaultFrequency: { type: String },
      defaultFrequency: { type: String }
    };
  }

  static get styles() {
    return css`
      :host {
        cursor: default;
        display: inline-block;
      }

      :host[hidden] {
        display: none;
      }

      .btn {
        position: relative;
        width: var(--og-radio-width, 1.4em);
        height: var(--og-radio-height, 1.4em);
        margin: var(--og-radio-margin, 0);
        padding: 0;
        border: 1px solid var(--og-primary-color, var(--og-border-color, black));
        background: #fff;
        border-radius: 100%;
        vertical-align: middle;
        color: var(--og-primary-color, var(--og-btn-color, black));
      }

      .radio {
        text-indent: -9999px;
        flex-shrink: 0;
      }

      .checkbox {
        border-radius: 3px;
      }
      .checkbox.active::after,
      .radio.active::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }

      .radio.active::after {
        content: ' ';
        border-radius: 100%;
        background: var(--og-primary-color, var(--og-radio-background-color, black));
        border: 2px solid #fff;
      }

      .checkbox.active::after {
        border: none;
        border-radius: 0;
        background: #fff;
        content: '\\2714';
        color: var(--og-primary-color, var(--og-checkbox-color, black));
        line-height: 1;
        text-align: center;
        overflow: visible;
      }
    `;
  }

  constructor() {
    super();
    this.addEventListener('click', this.handleClick.bind(this));
  }

  updated(changed) {
    if (changed.has('subscribed')) {
      this.frequencyMatch = this.frequency === this.defaultFrequency;
    }
  }

  handleClick() {}

  render() {
    if (this.subscribed && !this.defaultFrequency)
      return html`
        <slot name="subscribed"></slot>
        <slot name="frequency-mismatch"></slot>
      `;

    if (this.subscribed && this.defaultFrequency === this.frequency)
      return html`
        <slot name="subscribed"></slot>
        <slot name="frequency-match"></slot>
      `;

    if (this.subscribed && this.defaultFrequency !== this.frequency)
      return html`
        <slot name="subscribed"></slot>
        <slot name="frequency-mismatch"></slot>
      `;

    return html`
      <slot name="not-subscribed"></slot>
    `;
  }
}

export const mapStateToProps = (state, ownProps = {}) => ({
  subscribed: makeOptedinSelector(ownProps.product)(state),
  frequency: makeProductFrequencySelector(ownProps.product)(state),
  productDefaultFrequency: makeProductDefaultFrequencySelector((ownProps.product || {}).id)(state),
  ...configSelector(state, ownProps, 'defaultFrequency'),
  ...templatesSelector(state, ownProps)
});

export const ConnectedOptinStatus = connect(mapStateToProps)(OptinStatus);

export default ConnectedOptinStatus;
