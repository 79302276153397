import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';
import { stringifyFrequency } from './api';
import platform from '../platform';
import { mapFrequencyToSellingPlan, safeProductId } from '../core/utils';

memoize.Cache = Map;

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a === null || b === null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function resolveFrequency(sellingPlans, frequenciesEveryPeriod, frequency) {
  const ogFrequency = stringifyFrequency(frequency);
  if (!platform.shopify_selling_plans) return ogFrequency;
  return mapFrequencyToSellingPlan(sellingPlans, frequenciesEveryPeriod, ogFrequency);
}

export const isSameProduct = (a, b) => {
  if (a === b) return true;
  if (typeof a === 'object' && typeof b === 'object' && a && b) {
    if (a.id === b.id) {
      if (!(Array.isArray(a.components) && Array.isArray(b.components))) {
        return true;
      }
      if (arraysEqual((a.components || []).sort(), (b.components || []).sort())) {
        return true;
      }
    }
  }
  return false;
};

/**
 * Returns a list of opted in products id from the state
 * @param {object} state
 */
export const optedinSelector = state => state.optedin || [];

export const optedoutSelector = state => state.optedout || [];

export const autoshipSelector = state => state.autoshipByDefault || {};

export const defaultFrequenciesSelector = state => state.defaultFrequencies || {};

export const sellingPlansSelector = state => state?.config?.frequencies || [];

export const frequenciesEveryPeriodSelector = state => state?.config?.frequenciesEveryPeriod || [];

/**
 * Creates a function with state arguments that return the true when
 * productId is in the optedin array or not in optedout or autoship by default
 * @param {String} productId
 */
export const makeOptedinSelector = memoize(
  product =>
    createSelector(optedinSelector, optedoutSelector, autoshipSelector, (optedin, optedout, autoshipByDefault) => {
      const entry = optedin.find(b => isSameProduct(product, b));
      if (entry) {
        return entry;
      }
      if (optedout.find(b => isSameProduct(product, b))) {
        return false;
      }
      if (product && autoshipByDefault[product.id]) {
        return { id: product.id };
      }
      return false;
    }),
  product => JSON.stringify(product)
);
/**
 * Creates a function with state arguments that return the true when
 * productId is in the optedin array
 * @param {String} productId
 */
export const makeSubscribedSelector = memoize(
  product =>
    createSelector(optedinSelector, optedin => {
      const entry = optedin.find(b => isSameProduct(product, b));
      if (entry) {
        return entry;
      }
      return false;
    }),
  product => JSON.stringify(product)
);

/**
 * Creates a function with state arguments that return the true when
 * productId is in the optedout array
 * @param {String} productId
 */
export const makeOptedoutSelector = memoize(productId =>
  createSelector(optedoutSelector, optedout => optedout.find(b => isSameProduct(productId, b)))
);

export const frequencySelector = state => state.frequency;

export const makeProductFrequencySelector = memoize(productId =>
  createSelector(makeOptedinSelector(productId), productOptin => (productOptin && productOptin.frequency) || null)
);

/**
 * Creates a function with state arguments that returns stringified frequency
 * if default frequency exists for the product
 * @param {String} productId
 */
export const makeProductDefaultFrequencySelector = memoize(productId =>
  createSelector(
    defaultFrequenciesSelector,
    sellingPlansSelector,
    frequenciesEveryPeriodSelector,
    (defaultFrequencies, sellingPlans, frequenciesEveryPeriod) =>
      (defaultFrequencies[safeProductId(productId)] &&
        resolveFrequency(sellingPlans, frequenciesEveryPeriod, defaultFrequencies[safeProductId(productId)])) ||
      null
  )
);

/**
 * Convert a string from camel case to kebab case.
 * @param {String} string
 * @returns {String}
 */
export const kebabCase = string => {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

/**
 * Resolve element configuration by looking into html attribute or state config
 *
 * @param {Object} state
 * @param {HTMLElement} element
 * @param {String} key
 * @returns {Object}
 */
export const configSelector = (state, element, key, defaultValue) => ({
  [key]:
    (state.config && state.config[key]) ||
    (element && element.hasAttribute && element.hasAttribute(kebabCase(key)) && element[key]) ||
    (element.offer && typeof (element.offer[key] !== 'undefined') && element.offer[key]) ||
    defaultValue
});

/**
 * Returns a list of opted in products id from the state
 * @param {object} state
 */
export const templatesSelector = state => ({ templates: state.templates || [] });
