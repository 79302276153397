import { receiveOffer, receiveOrders, authorize, unauthorized, optinProduct, optoutProduct } from './actions';
import * as constants from './constants';

export const setPreviewStandardOffer = (isPreview, productId, offer) =>
  async function setPreviewStandardOfferThunk(dispatch) {
    await dispatch({
      type: constants.SET_PREVIEW_STANDARD_OFFER,
      payload: isPreview
    });
    await dispatch({
      type: constants.UNAUTHORIZED
    });
    await dispatch(
      receiveOffer(
        {
          in_stock: { [productId]: true },
          eligibility_groups: { [productId]: ['subscription', 'upsell'] },
          result: 'success',
          autoship: { [productId]: true },
          autoship_by_default: { [productId]: false },
          modifiers: {},
          module_view: { regular: '096135e6650111e9a444bc764e106cf4' },
          incentives_display: {
            '47c01e9aacbe40389b5c7325d79091aa': {
              field: 'sub_total',
              object: 'order',
              type: 'Discount Percent',
              value: 5
            },
            e6534b9d877f41e586c37b7d8abc3a58: {
              field: 'total_price',
              object: 'item',
              type: 'Discount Percent',
              value: 10
            },
            f35e842710b24929922db4a529eecd40: {
              field: 'total_price',
              object: 'item',
              type: 'Discount Percent',
              value: 10
            },
            '5be321d7c17f4e18a757212b9a20bfcc': {
              field: 'total_price',
              object: 'item',
              type: 'Discount Percent',
              value: 1
            }
          },
          incentives: {
            [productId]: {
              initial: ['5be321d7c17f4e18a757212b9a20bfcc'],
              ongoing: [
                'e6534b9d877f41e586c37b7d8abc3a58',
                '47c01e9aacbe40389b5c7325d79091aa',
                'f35e842710b24929922db4a529eecd40'
              ]
            }
          }
        },
        offer
      )
    );
  };

export const setPreviewUpsellOffer = (isPreview, productId, offer) =>
  async function setPreviewUpsellOfferThunk(dispatch, getState) {
    await dispatch({ type: constants.SET_PREVIEW_UPSELL_OFFER, payload: isPreview });

    const { merchantId } = getState();
    if (isPreview) {
      await dispatch(
        receiveOffer(
          {
            in_stock: { [productId]: true },
            module_view: { regular: '096135e6650111e9a444bc764e106cf4' },
            default_frequencies: { [productId]: { every: 1, every_period: 3 } },
            eligibility_groups: { [productId]: ['subscription', 'upsell'] },
            result: 'success',
            autoship: { [productId]: true },
            autoship_by_default: { [productId]: false },
            modifiers: {}
          },
          offer
        )
      );
      await dispatch(
        receiveOrders({
          count: 1,
          next: null,
          previous: null,
          results: [
            {
              merchant: '0e5de2bedc5e11e3a2e4bc764e106cf4',
              customer: 'TestCust',
              payment: 'e98e789aba0111e9b90fbc764e107990',
              shipping_address: 'b3a5816ae59611e78937bc764e1043b0',
              public_id: '23322d4a83eb11ea9a1ebc764e101db1',
              sub_total: '206.98',
              tax_total: '0.00',
              shipping_total: '10.00',
              discount_total: '0.00',
              total: '216.98',
              created: '2020-04-21 11:14:11',
              place: '2020-06-24 00:00:00',
              cancelled: null,
              tries: 0,
              generic_error_count: 0,
              status: 1,
              type: 1,
              order_merchant_id: null,
              rejected_message: null,
              extra_data: null,
              locked: false,
              oos_free_shipping: false
            }
          ]
        })
      );
      await dispatch(authorize(merchantId, 'sig_field', 'ts', 'sig'));
    } else {
      await dispatch(unauthorized());
    }
  };

export const setPreview = (value, oldValue, offer) =>
  async function(dispatch, getState) {
    await dispatch({ type: constants.LOCAL_STORAGE_CLEAR });
    await dispatch({
      type: constants.SET_PREVIEW_STANDARD_OFFER,
      payload: false
    });
    await dispatch({
      type: constants.SET_PREVIEW_UPSELL_OFFER,
      payload: false
    });

    switch (value) {
      case 'regular':
        dispatch(setPreviewStandardOffer(true, offer.product.id, offer));
        break;
      case 'upsell':
        dispatch(setPreviewUpsellOffer(true, offer.product.id, offer));
        break;
      case 'subscribed':
        dispatch(setPreviewStandardOffer(true, offer.product.id, offer));
        dispatch(optinProduct(offer.product, '2_2'));
        break;
      default:
    }
  };
