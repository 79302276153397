import { offersLiveEditor } from '@ordergroove/offers-live-editor';
import { setStore } from './core/connect';
import { listenLocalStorageChanges } from './core/localStorage';
import * as adapters from './core/adapters';
import * as actions from './core/actions';
import { ConnectedWhen } from './components/When';
import { ConnectedOptinButton } from './components/OptinButton';
import { ConnectedOptoutButton } from './components/OptoutButton';
import { ConnectedOptinSelect } from './components/OptinSelect';
import { ConnectedUpsellButton } from './components/UpsellButton';
import { ConnectedUpsellModal } from './components/UpsellModal';
import { ConnectedOptinToggle } from './components/OptinToggle';
import { ConnectedOptinStatus } from './components/OptinStatus';
import { ConnectedText } from './components/Text';
import { ConnectedIncentiveText } from './components/IncentiveText';
import { ConnectedSelectFrequency } from './components/SelectFrequency';
import { ConnectedNextUpcomingOrder } from './components/NextUpcomingOrder';
import { ConnectedOffer } from './components/Offer';
import { Modal } from './components/Modal';
import { Select } from './components/Select';
import { Tooltip } from './components/Tooltip';
import { ConnectedFrequencyStatus } from './components/FrequencyStatus';
import * as testMode from './test-mode';
import { api } from './core/api';
import { environment, offer } from './core/reducer';
import { DEFAULT_OFFER_MODULE, RECEIVE_PRODUCT_PLANS, SETUP_CART, SETUP_PRODUCT } from './core/constants';
import ConnectedPrice from './components/Price';
import { safeProductId } from './core/utils';

export default function makeApi(store) {
  testMode.enable();

  setStore(store);

  try {
    customElements.define('og-when', ConnectedWhen);
    customElements.define('og-text', ConnectedText);
    customElements.define('og-incentive-text', ConnectedIncentiveText);
    customElements.define('og-offer', ConnectedOffer);
    customElements.define('og-select-frequency', ConnectedSelectFrequency);
    customElements.define('og-optout-button', ConnectedOptoutButton);
    customElements.define('og-optin-toggle', ConnectedOptinToggle);
    customElements.define('og-optin-status', ConnectedOptinStatus);
    customElements.define('og-optin-button', ConnectedOptinButton);
    customElements.define('og-optin-select', ConnectedOptinSelect);
    customElements.define('og-upsell-button', ConnectedUpsellButton);
    customElements.define('og-frequency-status', ConnectedFrequencyStatus);
    customElements.define('og-modal', Modal);
    customElements.define('og-select', Select);
    customElements.define('og-tooltip', Tooltip);
    customElements.define('og-upsell-modal', ConnectedUpsellModal);
    customElements.define('og-next-upcoming-order', ConnectedNextUpcomingOrder);
    customElements.define('og-price', ConnectedPrice);
  } catch (err) {
    console.info('OG WebComponents already registered, skipping.');
  }

  // // use this syntax to allow es6 module be called as default function og.offers(...)
  // module.exports = offers.initialize;
  let isReady = false;
  const offers = {
    store,
    isReady: () => isReady,
    setEnvironment(e) {
      store.dispatch(actions.setEnvironment(e));
      return this;
    },
    setMerchantId(m) {
      store.dispatch(actions.setMerchantId(m));
      return this;
    },
    setAuthUrl(authUrl) {
      store.dispatch(actions.setAuthUrl(authUrl));
      return this;
    },
    getProductsForPurchasePost(productIds = []) {
      return adapters.getProductsForPurchasePost(store.getState(), productIds);
    },
    getOptins(productIds = []) {
      return adapters.getProductsForPurchasePost(store.getState(), productIds);
    },
    clear() {
      store.dispatch(actions.checkout());
    },
    addOptinChangedCallback(fn) {
      if (typeof fn === 'function') document.addEventListener('optin-changed', e => fn(e.detail));
    },
    disableOptinChangedCallbacks() {
      document.addEventListener('optin-changed', e => e.stopPropagation(), true);
    },

    register() {
      /* noop */
    },
    previewMode(set) {
      window.og = window.og || {};
      if (set === false) {
        delete window.og;
      } else {
        window.og.previewMode = true;
      }
      return this;
    },
    config(configuration) {
      store.dispatch(actions.setConfig(configuration));
      return this;
    },
    setLocale(locale) {
      store.dispatch(actions.setLocale(locale));
      return this;
    },
    addTemplate(tagName, content, configOption) {
      store.dispatch(actions.addTemplate(tagName, content, configOption));
      return this;
    },
    /**
     * templates object where keys are selectors and values are content
     */
    setTemplates(templates) {
      store.dispatch(actions.setTemplates(templates));
      return this;
    },

    setPublicPath(publicPath) {
      return this;
    },

    resolveSettings(merchantId, env, settings, storeInstance = store) {
      if (merchantId && env && settings) {
        let products = [];
        if (settings.product) {
          products.push(settings.product);
        } else if (settings.cart && Array.isArray(settings.cart.products)) {
          products = products.concat(settings.cart.products);
        }
        const { apiUrl } = environment({}, actions.setEnvironment(env));
        const { sessionId } = storeInstance.getState();
        if (sessionId) {
          products.forEach(product => {
            const id = safeProductId(product);
            api.fetchOffer(apiUrl, merchantId, sessionId, `${id}`, DEFAULT_OFFER_MODULE);
          });
        }

        if (settings.product_discounts && typeof settings.product_discounts === 'object') {
          storeInstance.dispatch({ type: RECEIVE_PRODUCT_PLANS, payload: settings.product_discounts });
        }
      }
    },

    /**
     * Initialize OG object
     * @param {*} merchantId
     * @param {*} env
     * @param {*} authUrl
     */
    initialize(merchantId, env, authUrl) {
      // settings resolves once, before anything.
      if (isReady) {
        console.warn('og.offers has been initialized already. Skipping.');
      } else {
        offers.resolveSettings(merchantId, env, window.og_settings, store);
      }

      const state = store.getState();
      // dont re-trigger actions if value is the same. allowing set new authurl only
      if (merchantId && merchantId !== state.merchantId) offers.setMerchantId(merchantId);
      if (env && env !== state.environment?.name) offers.setEnvironment(env);
      // allow set new authUrl
      if (authUrl) offers.setAuthUrl(authUrl);

      isReady = true;

      return this;
    }
  };

  window.OG = window.OG || {};
  Object.assign(window.OG, offers);
  Object.assign(offers.initialize, offers);

  offersLiveEditor(window.opener, offers);

  return offers;
}
