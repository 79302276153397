/* eslint-disable camelcase */
import { parseFrequency } from './api';

/**
 * Converst the current redux state to purchase
 * post compatible json
 */
export const getProductsForPurchasePost = (state = {}, productIds = []) =>
  (state.optedin || [])
    .map(optin => {
      const purchasePostObject = {
        product: optin.id,
        subscription_info: {
          components: optin.components || []
        },
        tracking_override: {
          offer: ((state.productOffer || {})[optin.id] || [])[0],
          ...parseFrequency(optin.frequency)
        }
      };
      if (state.firstOrderPlaceDate && state.firstOrderPlaceDate[optin.id]) {
        purchasePostObject.subscription_info.first_order_place_date = state.firstOrderPlaceDate[optin.id];
      }
      if (state.productToSubscribe && state.productToSubscribe[optin.id]) {
        purchasePostObject.tracking_override.product = state.productToSubscribe[optin.id];
      }
      return purchasePostObject;
    })
    .filter(optin => optin.tracking_override.offer)
    .filter(optin => (productIds.length ? productIds.includes(optin.product) : optin));

export default { getProductsForPurchasePost };
